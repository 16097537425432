body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(213,159,56,1) 100%)!important;
  min-height: 100vh;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.login-hero-container {
  background: #4f37ea;
  background-image: radial-gradient(#ffffffad 1px, transparent 0);
  background-size: 40px 40px;
  background-position: -19px -19px;
  height: auto;
  color: white;
  padding: 1em;
  display: flex;
  align-items: flex-end;
}

.logotype {
  color: #4f37ea;
  font-weight: bolder;
  font-size: 1.8em;
}

.array {
  display: flex;
}

.vertical {
  flex-direction: column;
}

.vertical-reverse {
  flex-direction: column-reverse;
}


.horizontal {
  flex-direction: row;
}

.horizontal-reverse {
  flex-direction: row-reverse;
}

.array.gaped {
  gap: 1em;
}

.array.gaped-1-2 {
  gap: calc(1em / 2);
}

.array.gaped-1-3 {
  gap: calc(1em / 3);
}

.array.gaped-1-4 {
  gap: calc(1em / 4);
}

.array.padded-top-1-1 {
  padding-top: 1em;
}

.array.padded-1-1 {
  padding: 1em;
}

.array.padded-top-1-2 {
  padding-top: calc(1em / 2);
}

.array.padded-1-2 {
  padding: calc(1em / 2);
}

.array.padded-top-1-3 {
  padding-top: calc(1em / 3);
}

.array.padded-1-3 {
  padding: calc(1em / 3);
}

.array.padded-top-1-4 {
  padding-top: calc(1em / 4);
}

.array.padded-1-4 {
  padding: calc(1em / 4);
}

.array.padded-top-1-5 {
  padding-top: calc(1em / 5);
}

.array.padded-1-5 {
  padding: calc(1em / 5);
}

.array.justified-between {
  justify-content: space-between;
}

.array.justified-evenly {
  justify-content: space-evenly;
}

.login-details {
  padding: 3em 5em;
  width: 65%;
}

.card {
  display: flex;
  min-height: 30vh;
}

.card.bordered {
  background: white;
  border: 1px #4f37ea solid;
}

.nav-bar {
  box-shadow: 0px 7px 20px 0px #f1f1f1;
}

.bold {
  font-weight: bold;
}

.asset {
  font-weight: bold;
  font-size: 2em;
}

.asset.positive {
  color: #4c7f5b;
}

.asset.negative {
  color: #a6606b;
}

.subtle {
  color: #a89bf1;
  font-style: italic;
}

.snazzy-background {
  background: #4f37ea;
  margin: 0;
  font-weight: 100;
  -webkit-overflow-Y: hidden;
  -moz-overflow-Y: hidden;
  -o-overflow-Y: hidden;
  overflow-y: hidden;
  -webkit-animation: fadeIn 1 1s ease-out;
  -moz-animation: fadeIn 1 1s ease-out;
  -o-animation: fadeIn 1 1s ease-out;
  animation: fadeIn 1 1s ease-out;
}

.light {
  height: 100%;
  position: relative;
  width: 0px;
  opacity: .75;
  background-color: white;
  box-shadow: #e9f1f1 0px 0px 20px 2px;
  top: 100vh;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
}

.x1{
  -webkit-animation: floatUp 4s infinite linear;
  -moz-animation: floatUp 4s infinite linear;
  -o-animation: floatUp 4s infinite linear;
  animation: floatUp 4s infinite linear;
  -webkit-transform: scale(1.0);
  -moz-transform: scale(1.0);
  -o-transform: scale(1.0);
  transform: scale(1.0);
}

.x2{
  -webkit-animation: floatUp 7s infinite linear;
  -moz-animation: floatUp 7s infinite linear;
  -o-animation: floatUp 7s infinite linear;
  animation: floatUp 7s infinite linear;
  -webkit-transform: scale(1.6);
  -moz-transform: scale(1.6);
  -o-transform: scale(1.6);
  transform: scale(1.6);
  left: 15%;
}

.x3{
  -webkit-animation: floatUp 2.5s infinite linear;
  -moz-animation: floatUp 2.5s infinite linear;
  -o-animation: floatUp 2.5s infinite linear;
  animation: floatUp 2.5s infinite linear;
  -webkit-transform: scale(.5);
  -moz-transform: scale(.5);
  -o-transform: scale(.5);
  transform: scale(.5);
  left: -15%;
}

.x4{
  -webkit-animation: floatUp 4.5s infinite linear;
  -moz-animation: floatUp 4.5s infinite linear;
  -o-animation: floatUp 4.5s infinite linear;
  animation: floatUp 4.5s infinite linear;
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
  left: -34%;
}

.x5{
  -webkit-animation: floatUp 8s infinite linear;
  -moz-animation: floatUp 8s infinite linear;
  -o-animation: floatUp 8s infinite linear;
  animation: floatUp 8s infinite linear;
  -webkit-transform: scale(2.2);
  -moz-transform: scale(2.2);
  -o-transform: scale(2.2);
  transform: scale(2.2);
  left: -57%;
}

.x6{
  -webkit-animation: floatUp 3s infinite linear;
  -moz-animation: floatUp 3s infinite linear;
  -o-animation: floatUp 3s infinite linear;
  animation: floatUp 3s infinite linear;
  -webkit-transform: scale(.8);
  -moz-transform: scale(.8);
  -o-transform: scale(.8);
  transform: scale(.8);
  left: -81%;
}

.x7{
  -webkit-animation: floatUp 5.3s infinite linear;
  -moz-animation: floatUp 5.3s infinite linear;
  -o-animation: floatUp 5.3s infinite linear;
  animation: floatUp 5.3s infinite linear;
  -webkit-transform: scale(3.2);
  -moz-transform: scale(3.2);
  -o-transform: scale(3.2);
  transform: scale(3.2);
  left: 37%;
}

.x8{
  -webkit-animation: floatUp 4.7s infinite linear;
  -moz-animation: floatUp 4.7s infinite linear;
  -o-animation: floatUp 4.7s infinite linear;
  animation: floatUp 4.7s infinite linear;
  -webkit-transform: scale(1.7);
  -moz-transform: scale(1.7);
  -o-transform: scale(1.7);
  transform: scale(1.7);
  left: 62%;
}

.x9{
  -webkit-animation: floatUp 4.1s infinite linear;
  -moz-animation: floatUp 4.1s infinite linear;
  -o-animation: floatUp 4.1s infinite linear;
  animation: floatUp 4.1s infinite linear;
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -o-transform: scale(0.9);
  transform: scale(0.9);
  left: 85%;
}

button:focus{
  outline: none;
}

@-webkit-keyframes floatUp{
  0%{top: 100vh; opacity: 0;}
  25%{opacity: 1;}
  50%{top: 0vh; opacity: .8;}
  75%{opacity: 1;}
  100%{top: -100vh; opacity: 0;}
}
@-moz-keyframes floatUp{
  0%{top: 100vh; opacity: 0;}
  25%{opacity: 1;}
  50%{top: 0vh; opacity: .8;}
  75%{opacity: 1;}
  100%{top: -100vh; opacity: 0;}
}
@-o-keyframes floatUp{
  0%{top: 100vh; opacity: 0;}
  25%{opacity: 1;}
  50%{top: 0vh; opacity: .8;}
  75%{opacity: 1;}
  100%{top: -100vh; opacity: 0;}
}
@keyframes floatUp{
  0%{top: 100vh; opacity: 0;}
  25%{opacity: 1;}
  50%{top: 0vh; opacity: .8;}
  75%{opacity: 1;}
  100%{top: -100vh; opacity: 0;}
}

@-webkit-keyframes fadeIn{
  from{opacity: 0;}
  to{opacity: 1;}
}

@-moz-keyframes fadeIn{
  from{opacity: 0;}
  to{opacity: 1;}
}

@-o-keyframes fadeIn{
  from{opacity: 0;}
  to{opacity: 1;}
}

@keyframes fadeIn{
  from{opacity: 0;}
  to{opacity: 1;}
}

@-webkit-keyframes fadeOut{
  0%{opacity: 0;}
  30%{opacity: 1;}
  80%{opacity: .9;}
  100%{opacity: 0;}
}

@-moz-keyframes fadeOut{
  0%{opacity: 0;}
  30%{opacity: 1;}
  80%{opacity: .9;}
  100%{opacity: 0;}
}

@-o-keyframes fadeOut{
  0%{opacity: 0;}
  30%{opacity: 1;}
  80%{opacity: .9;}
  100%{opacity: 0;}
}

@keyframes fadeOut{
  0%{opacity: 0;}
  30%{opacity: 1;}
  80%{opacity: .9;}
  100%{opacity: 0;}
}

@-webkit-keyframes finalFade{
  0%{opacity: 0;}
  30%{opacity: 1;}
  80%{opacity: .9;}
  100%{opacity: 1;}
}

@-moz-keyframes finalFade{
  0%{opacity: 0;}
  30%{opacity: 1;}
  80%{opacity: .9;}
  100%{opacity: 1;}
}

@-o-keyframes finalFade{
  0%{opacity: 0;}
  30%{opacity: 1;}
  80%{opacity: .9;}
  100%{opacity: 1;}
}

@keyframes finalFade{
  0%{opacity: 0;}
  30%{opacity: 1;}
  80%{opacity: .9;}
  100%{opacity: 1;}
}

.red-shadow {
  box-shadow: 0 1px 4px #ee203e;
}

.green-shadow {
  box-shadow: 0 1px 4px #20ee79;
}

.no-elem-text {
  display: flex;
}

* + .no-elem-text {
  display: none!important;
}

.sub-text {
  font-size: .7em;
}

.default-button {
  background: #4f4f4f;
}

.app-link {
  color: #4f4f4f;
}

.native-button {
  height: 1.5em;
  width: 100%;
  cursor: pointer;
}

.label {
  font-weight: bold;
  font-size: 0.8em;
}

.button-with-icon {
  display: flex;
  gap: 5px;
  padding: 5px 10px;
  background: white;
  color: #3a4059;
  margin: 1px;
  animation-duration: .5s;
  border-radius: 5px;
}