body {
  background: #f6f6f6;
  touch-action: pan-x pan-y;
}

a {
  text-decoration: none;
}

.centered {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  align-items: center;
}

.link {
  font-weight: bold;
}

.header {
  font-size: 2em;
  font-weight: bold;
}

.checkbox-selector {
  padding-bottom: .6em;
  font-weight: bold;
  text-align: left;
}

.top-bar {
  display: flex;
  flex-direction: column;
  margin: .25em 20vw;
}


@media only screen and (max-width: 600px) {
  .top-bar {
    margin: 1em 1em 0 1em;
  }
}


.app {
  height: 90vh;
  display: flex;
  flex-direction: column;
  margin: 10vh 20vw;
}


@media only screen and (max-width: 600px) {
  .app {
    margin: 0em 1em;
    height: calc(100vh - 7em);
  }
}

.btn-main-selector {
  padding: 1em;
  background: white;
  color: #3a4059;
  font-weight: bold;
  font-size: 1.3em;
  margin: 1px;
  animation-duration: .5s;
  text-decoration: auto;
  border-radius: 5px;
}

.btn-main-selector:hover {
  background: #1e48e5;
}

.chat-list-container {

}

.chat-list-item {
  display: flex;
  margin: .5em 0
}

.chat-list-item:hover {
  background: #1e48e5;
}

.chat-list-item .icon {
  background: #dcdcdc;
  border-radius: 50px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
}

button, input[type="submit"], input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.bottom-bar {
  background: #ffd56b;
  height: 4em;
  display: flex;
  align-content: center;
  justify-content: space-evenly;
  align-items: center;

  outline: 0;
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
  color: #000;
  box-sizing: border-box;
  padding-bottom: 0.5em;
}

.bottom-bar .active {
  color: #fff;
  border-radius: 2px;
}

.bottom-bar-container {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
}

.bottom-bar button {
  color: #151515;
  font-size: .7em;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding: 0 4px;
}

.button-array-right {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
}

.smol-date {
  font-size: 0.8em;
  text-align: right;

}

.carousel {
  overflow: hidden;
}

.inner {
  white-space: nowrap;
  transition: transform 0.3s;
}

.carousel-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #fff;
  width: 100vw;
}

.indicators {
  display: flex;
  justify-content: center;
}

.indicators > button {
  margin: 5px;
}

.indicators > button.active {
  color: #fff;
}

.responsive-img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

.carousel-centered {
  position: fixed;
  top: 0;
  padding-top: 37vh;
}

.actions-array {
  display: flex;
  gap: 3px;
}